import { Button, Input, NativeSelect } from "@mantine/core"
import axios from "axios"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useServerClient } from "../contexts/ServerClient"
import s from "./index.module.css"

type Ass = {
  id: number
  name: string
  role_name: string
  description: string
  git_url: string
}

export default function HomePage() {
  const [asses, setAsses] = useState<Ass[]>([])
  const { serverClient } = useServerClient()
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()

  const onSubmit = (d: any) => {
    reset()
    serverClient.post("/created_assignments", {
      name: d.applicantName,
      assignment: d.assignmentName,
      git_url: asses.find((a) => a.name === d.assignmentName)!.git_url,
      email: d.applicantEmail,
    })
  }

  useEffect(() => {
    axios
      .get("https://thetc.dev/api/v4/groups/766", {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_RECR_GROUP_TOKEN}`,
        },
      })
      .then((resp) => {
        if (resp.status !== 200) return

        const projects: Ass[] = resp.data.projects.map(
          (p: any) =>
            ({
              id: p.id,
              name: p.name,
              description: p.description,
              git_url: p.ssh_url_to_repo,
              role_name: "Role",
            } as Ass)
        )

        setAsses(projects)
      })
      .catch((e) => console.error(e))
  }, [])

  const availableAssignments = asses.map((ass) => ({
    value: ass.name,
    label: ass.name,
  }))
  const selectAssignmentdata = [
    { value: "", label: "Select assignment" },
    ...availableAssignments,
  ]

  return (
    <div className={s.page}>
      <h3>Create assignment repo for an applicant:</h3>
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label htmlFor="applicant_name">Applicant name: </label>
          <Input
            type="text"
            id="applicant_name"
            {...register("applicantName", { required: "Required" })}
          />
          {errors.applicantName && errors.applicantName.message}
        </div>
        <div>
          <label htmlFor="applicant_email">Applicant email: </label>
          <Input
            type="email"
            id="applicant_email"
            {...register("applicantEmail", { required: "Required" })}
          />
          {errors.applicantEmail && errors.applicantEmail.message}
        </div>
        <div>
          <label htmlFor="assignment">Assignments: </label>
          <NativeSelect
            data={selectAssignmentdata}
            id="assignment"
            {...register("assignmentName", { required: "Required" })}
          />
          {errors.assignmentId && errors.assignmentId.message}
        </div>
        <div className={s.btnWrapper}>
          <Button size="md" type="submit" className={s.btn}>
            Create assignment repo
          </Button>
        </div>
      </form>
    </div>
  )
}
