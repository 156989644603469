import { Collapse, CopyButton, UnstyledButton } from "@mantine/core"
import { useEffect, useState } from "react"
import { useServerClient } from "../../contexts/ServerClient"
import s from "./index.module.css"

type CreatedAssignment = {
  id: string
  name: string
  git_url: string
  email: string
  assignment: string
}

export default function CreatedAssignmentsPage() {
  const [createdAssignments, setCreatedAssignments] = useState<
    CreatedAssignment[]
  >([])
  const { serverClient } = useServerClient()
  const [openAssignments, setOpenAssignments] = useState(new Set<String>())

  useEffect(() => {
    serverClient
      .get<CreatedAssignment[]>("/created_assignments")
      .then((resp) => {
        const assignments = resp.data?.reverse()
        setCreatedAssignments(assignments || [])
        setOpenAssignments(() => new Set([assignments[0]?.id]))
      })
  }, [])

  const addOpenAssignment = (key: string) => {
    setOpenAssignments((s) => {
      s.add(key)
      return new Set(s)
    })
  }

  const removeOpenAssignment = (key: string) => {
    setOpenAssignments((s) => {
      s.delete(key)
      return new Set(s)
    })
  }

  const toggleOpenAssignemt = (key: string) => {
    openAssignments.has(key)
      ? removeOpenAssignment(key)
      : addOpenAssignment(key)
  }

  return (
    <div className={s.page}>
      <h3>Created assignments</h3>
      <div className={s.grid}>
        <p>Assignments created for:</p>
        {createdAssignments.map((createdAssignment) => {
          const isOpen = openAssignments.has(createdAssignment.id)
          return (
            <div key={createdAssignment.id} className={s.assignment}>
              <p
                onClick={() => toggleOpenAssignemt(createdAssignment.id)}
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                <span
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {isOpen ? "▲" : "▼"}
                </span>
                {createdAssignment.name}
              </p>
              <Collapse in={isOpen}>
                <div className={s.values}>
                  <p>
                    <strong>applicant email:</strong>{" "}
                    <Value text={createdAssignment.email} />
                  </p>
                  <p>
                    <strong>repo git-url:</strong>{" "}
                    <Value text={createdAssignment.git_url} />
                  </p>
                  <p>
                    <strong>assignment:</strong>{" "}
                    <Value text={createdAssignment.assignment} />
                  </p>
                  <p>
                    <strong>id:</strong> <Value text={createdAssignment.id} />
                  </p>
                </div>
              </Collapse>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const Value = ({ text }: { text: string }) => {
  return (
    <CopyButton value={text} timeout={500}>
      {({ copied, copy }) => (
        <>
          <UnstyledButton onClick={copy} className={s.copyBtn}>
            {text}
          </UnstyledButton>{" "}
          {copied && (
            <span style={{ fontSize: "14px", color: "green" }}>copied</span>
          )}
        </>
      )}
    </CopyButton>
  )
}
