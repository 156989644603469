import { BrowserRouter, Routes, Route } from "react-router-dom"
import SentAssignmentsPage from "./pages/created_assignments/index.page"
import HomePage from "./pages/index.page"

import { useServerClient } from "./contexts/ServerClient"
import Navbar from "./components/navbar"

export default function Router() {
  const { isSignedIn } = useServerClient()
  return (
    <BrowserRouter>
      <Navbar />
      {isSignedIn ? (
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/created-assignments"
            element={<SentAssignmentsPage />}
          />
        </Routes>
      ) : (
        <h1 style={{ textAlign: "center", marginTop: "70px" }}>
          Sign in to view
        </h1>
      )}
    </BrowserRouter>
  )
}
