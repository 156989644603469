import { Link } from "react-router-dom"
import { Anchor, Button, Header } from "@mantine/core"

import { useServerClient } from "../contexts/ServerClient"
import { ReactComponent as ThetcLogo } from "../thetc-logo.svg"
import s from "./navbar.module.css"

export default function Navbar() {
  const { isSignedIn, openAuthScreen, signOut } = useServerClient()
  return (
    <Header height={80} className={s.navbar}>
      <ThetcLogo style={{ width: "30px", height: "30px" }} />
      <Link to="/">Create assignment repo</Link>
      <Link to="/created-assignments">View created assignments</Link>

      {isSignedIn ? (
        <Button
          onClick={() => {
            signOut()
          }}
        >
          Sign Out
        </Button>
      ) : (
        <Button onClick={openAuthScreen}>Sing In</Button>
      )}
    </Header>
  )
}
