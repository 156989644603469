import axios from "axios"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

const ServerClientContext = createContext({
  serverClient: axios.create({}),
  isSignedIn: false,
  openAuthScreen: () => {},
  signOut: () => {},
})

function openSignInPortal({ google_client_id }: { google_client_id: string }) {
  const signInUrl = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${window.location.origin}&client_id=${google_client_id}&access_type=offline&response_type=code&prompt=consent&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email`
  window.location.assign(signInUrl)
}

export function ServerClientProvider({ children }: { children: ReactNode }) {
  const [signedIn, setSignedIn] = useState(false)
  const authToken = localStorage.getItem("google_token")

  const signOut = useCallback(() => {
    localStorage.removeItem("google_token")
    setSignedIn(false)
  }, [])

  const signIn = (token: string) => {
    localStorage.setItem("google_token", token)
    setSignedIn(true)
  }

  const serverClient = useMemo(() => {
    const client = axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL,
      headers: { Authorization: `Bearer ${authToken}` },
    })

    client.interceptors.response.use(
      (resp) => {
        return resp
      },
      (error) => {
        console.error(error)

        if (error?.response?.status === 401) {
          signOut()
        }
        return error
      }
    )

    return client
  }, [authToken])

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code")
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/auth/google/user`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((d) => d.status === 200 && setSignedIn(true))

    if (code) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/auth/google`, {
          params: { code },
        })
        .then((d) => {
          if (d.data.access_token) {
            signIn(d.data.access_token)
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          window.history.replaceState(
            {},
            document.title,
            window.location.origin
          )
        })
    }
  }, [])

  if (process.env.REACT_APP_GOOGLE_CLIENT_ID == null) {
    console.error("Google client id must be set")
  }

  if (process.env.REACT_APP_SERVER_URL == null) {
    console.error("Server url must be set")
  }

  return (
    <ServerClientContext.Provider
      value={{
        serverClient,
        signOut,
        openAuthScreen: () =>
          openSignInPortal({
            google_client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? "",
          }),
        isSignedIn: signedIn,
      }}
    >
      {children}
    </ServerClientContext.Provider>
  )
}

export function useServerClient() {
  return useContext(ServerClientContext)
}
