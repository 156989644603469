import Router from "./router"
import { MantineProvider } from "@mantine/core"
import { ServerClientProvider } from "./contexts/ServerClient"

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <ServerClientProvider>
        <Router />
      </ServerClientProvider>
    </MantineProvider>
  )
}

export default App
