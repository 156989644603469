import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// client id: 688510636180-0tvh9vb1lnrpk739t3qlnv581r1cepif.apps.googleusercontent.com
// client secret: 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
